<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-icon left>mdi-wrench</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Maintenance Requests ({{ totalMaintenances }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-tooltip>
        <template v-slot:activator="{ on }">
          <v-btn
            color="#383d3f"
            class="mr-1"
            icon
            right
            @click="exportList"
            v-on="on"
            :disabled="loading"
          >
            <v-icon>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>

    <div
      class="mx-4"
      id="maintenance-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.value"
            :ref="item.value"
            :disabled="defaultStatus !== item.value && loading"
            @click="handleTabClick(item.value)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title, building, category and urgency"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="maintenances"
        v-model="selected"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetch"
        :server-items-length="totalMaintenances"
        :sort-by.sync="defaultFilter"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.status="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
        <template v-slot:item.building="{ item }">
          <div v-if="item.buildingName">{{ item.buildingName }}</div>
        </template>
        <template v-slot:item.property="{ item }">
          <div v-if="item?.user?.property">
            {{ item?.user?.property?.name }}
          </div>
        </template>
        <template v-slot:item.category="{ item }">
          <div v-if="item.categoryLabel">{{ item.categoryLabel }}</div>
        </template>
        <template v-slot:item.urgency="{ item }">
          <div v-if="item.urgencyLabel">{{ item.urgencyLabel }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(maintenances.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Request list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import Status from '@/components/common/Status'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import MAINTENANCE_STATUS from '@/utils/enums/MaintenanceStatus'
import { sortFilter, perPageFilter } from '@/utils/helpers'

export default {
  name: 'MaintenancesPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    Status,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      defaultStatus: 'pending',
      defaultFilter: 'created_at',
      sortOrder: false,
      tabItems: [
        { tab: 'Pending', value: 'pending', index: 0 },
        { tab: 'In progress', value: 'in_progress', index: 1 },
        { tab: 'Finished', value: 'done', index: 2 },
        { tab: 'Cancelled', value: 'cancelled', index: 3 },
        { tab: 'All', value: 'all', index: 4 },
      ],
      selected: [],
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '18%',
        },
        {
          text: 'Property',
          value: 'property',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Urgency',
          value: 'urgency',
          width: '10%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'center',
          width: '15%',
        },

        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
        {
          text: 'Last Updated',
          value: 'updated_at',
          width: '13%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      pageTables: 0,
      itemsPerPageTables: 25,
      exportParams: null,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      maintenances: (state) => state.maintenance.list,
      listMeta: (state) => state.maintenance.listMeta,
      totalMaintenances: (state) => state.maintenance.totalMaintenances,
      paramsMaintenance: (state) => state.maintenance.queryParams,
      scrollPosition: (state) => state.maintenance.scrollPosition,
    }),

    statusFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        ...MAINTENANCE_STATUS,
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ]
    },

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getMaintenances: 'maintenance/getMaintenances',
      exportToExcel: 'maintenance/exportToExcel',
    }),

    ...mapMutations({
      clearMaintenances: 'maintenance/clearMaintenanceList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'maintenance/setQueryParams',
      setScrollPosition: 'maintenance/setScrollPosition',
    }),

    filterList(filterValue) {
      this.fetch(null, filterValue)
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'created_at'
      this.itemsPerPageTables = 25
      this.fetch()
    },

    fetchInitParams() {
      if (this.paramsMaintenance?.filter) {
        this.defaultStatus = this.paramsMaintenance.filter
      }

      if (this.paramsMaintenance?.itemsPerPage) {
        const { itemsPerPage } = this.paramsMaintenance
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsMaintenance?.sort) {
        if (this.paramsMaintenance.sort[0].includes('-')) {
          this.defaultFilter = this.paramsMaintenance.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsMaintenance?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'created_at'
      }

      if (this.paramsMaintenance?.search) {
        this.filter.search = this.paramsMaintenance.search
      }

      this.fetch(null, 1, this.paramsMaintenance)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsMaintenance = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: perPageFilter(
          options,
          this.totalMaintenances,
          this.itemsPerPageTables
        ),
        filter:
          typeof this.defaultStatus === 'number'
            ? this.tabItems.find((item) => item.index === this.defaultStatus)
                ?.value
            : this.defaultStatus,
        sort: sortFilter(
          options,
          this.defaultFilter,
          this.sortOrder,
          'created_at'
        ),
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearMaintenances()
      this.loading = true
      await this.getMaintenances(paramsMaintenance || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
      this.exportParams = paramsMaintenance || params
    },
    editItem(item) {
      this.$router.push({
        name: 'maintenance.request.details',
        params: { id: item.id },
      })
    },

    async exportList() {
      this.setLoadingBuilding(true)
      this.loading = true

      await this.exportToExcel(this.exportParams)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'maintenances_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
          this.setLoadingBuilding(false)
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__status {
      margin-bottom: 15px;
    }

    &__status,
    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
